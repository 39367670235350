<template>
    <div class="vitals-graph-body">
        <div class="vitals-graph-container">
            <v-chart v-if='xAxisValues.length > 0 && yAxisValues.length > 0' class="chart" :option="getOption()"
                autoresize />
            <div v-else>
                <h3 class="graphical-view-no-reading">Vital Readings not available</h3>
            </div>
            <div id="myDiv"></div>
        </div>
        <div class="vitals-stat-container" v-if='xAxisValues.length > 0 && yAxisValues.length > 0'>
            <div class="stat-block">
                <div class="stat-block-item">
                    <span class="stat-block-heading">Min</span>
                    <div class="stat-block-value">
                        <span v-if="selectedVital.vitalId === 'heartRate'||selectedVital.vitalId === 'temp'" class="value">{{ minVal.slice(0, 5) }}</span>
                        <span v-if="selectedVital.vitalId === 'spo2'" class="value">{{ minVal.slice(0, 5) + '%' }}</span>
                        <span class="unit">{{ selectedVital.unit }}</span>
                    </div>
                </div>
                <div class="stat-block-item">
                    <span class="stat-block-heading">Max</span>
                    <div class="stat-block-value">
                        <span v-if="selectedVital.vitalId === 'heartRate'||selectedVital.vitalId === 'temp'" class="value">{{ maxVal.slice(0, 5) }}</span>
                        <span v-if="selectedVital.vitalId === 'spo2'" class="value">{{ maxVal.slice(0, 5) + '%' }}</span>
                        <span class="unit">{{ selectedVital.unit }}</span>
                    </div>
                </div>
                <div class="stat-block-item">
                    <span class="stat-block-heading">Average</span>
                    <div class="stat-block-value">
                        <span v-if="selectedVital.vitalId === 'heartRate'||selectedVital.vitalId === 'temp'" class="value">{{ avgVal.slice(0, 5) }}</span>
                        <span v-if="selectedVital.vitalId === 'spo2'" class="value">{{ avgVal.slice(0, 5) + '%' }}</span>
                        <span class="unit">{{ selectedVital.unit }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
} from 'echarts/components';
import dayjs from '@/util/dayjs';
import VChart, { THEME_KEY } from 'vue-echarts';
import * as echarts from 'echarts';
import {calculateAverage} from '@/util/helpers/common.helpers.js';
use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
]);
export default {
    name: 'LineWithAreaChart',
    components: {
        VChart,
    },
    data() {
        return {

            buttons: [
                { caption: 'Weekly', state: true },
                { caption: 'Monthly', state: false },
                { caption: 'Custom Date', state: false },

            ],
            selectedVitalPeriod: 'Weekly',
            vitalStartDate: null,
            vitalEndDate: null,
            customRangeStartvalue: '',
            customRangeEndvalue: '',
            formatted: '',
            selected: '',
            yAxisValues: [],
            xAxisValues: [],
            minVal: null,
            maxVal: null,
            avgval: null,
        }
    },
    props: {
        selectedVital: {
            type: Object,
            required: false
        },

        graphData: {
            type: Object||null,
            required: true
        },
        unit: {
            type: String,
            required: true,
            default: ''
        },
    },
    methods: {

        vitalPeriodClickHandler(period) {
            this.selectedVitalPeriod = period
        },
        getOption() {

            return {
                // title: {
                //     text: 'Stacked Line'
                // },
                tooltip: {
                    trigger: 'axis',
                    valueFormatter: (value) => { return this.selectedVital.vitalId === 'spo2' ? `${value + '%'} ${this.selectedVital.unit}` : `${value} ${this.selectedVital.unit}` }
                },
                // legend: {
                // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
                // },
                grid: {
                    left: '4%',
                    // right: '4%',
                    top: '3%',
                    bottom: '2%',
                    containLabel: true,
                    // show:true,
                    width: "auto",
                    background: "red",
                    // backgroundColor:"red",
                    backgroundColor:
                        "#ccc",
                },
                // toolbox: {
                //     feature: {
                //         saveAsImage: {}
                //     }
                // },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.xAxisValues,
                    axisLabel: {
                        show: true,
                        // interval:2,
                        // interval: (index, value, x) => {
                        //   if (index%5==0)
                        //     return false
                        //   return true
                        // },
                        rotate: 60,
                        // fontSize:"8px",
                        position: 'bottom'
                    },
                    splitLine: {
                        show: true
                    },
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: this.selectedVital.label,
                        type: 'line',
                        stack: 'Total',
                        showSymbol: false,
                        data: this.yAxisValues,
                        lineStyle: {
                            color: "#5359c3",
                            width: 4,
                            opacity: 0.8
                        },
                        areaStyle: {
                            opacity: 0.1,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(58,77,233,0.8)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(58,70,233,0.1)'
                                }
                            ])
                        },
                    },

                ]
            }
        },
        formatRawVitalData(rawData) {
            let { avg, max, min, readings } = rawData
            let readingsFormatted = []
            if (readings && readings.length > 0) {
                if (this.selectedVital.vitalId === 'heartRate')
                    readingsFormatted = readings.map((item) => ({ readAt: dayjs(item.readAt).format('MM/DD/YY h:mm A'), reading: Number(JSON.parse(item.reading).pulse) })).reverse()
                else if (this.selectedVital.vitalId === 'spo2')
                    readingsFormatted = readings.map((item) => ({ readAt: dayjs(item.readAt).format('MM/DD/YY h:mm A'), reading: Number(JSON.parse(item.reading).oxygen) })).reverse()
                else if (this.selectedVital.vitalId === 'temp')
                    readingsFormatted = readings.map((item) => ({ readAt: dayjs(item.readAt).format('MM/DD/YY h:mm A'), reading: Number(JSON.parse(item.reading).temperature) })).reverse()
                    this.minVal = min.value
                this.maxVal = max.value
                this.avgVal = avg!==null?avg:calculateAverage(readingsFormatted.map(item=>item.reading))+""
                this.yAxisValues = [
                    ...readingsFormatted.map(item => item.reading)
                ]
                this.xAxisValues = [
                    ...readingsFormatted.map(item => item.readAt),
                ]
            }
            else {
                this.xAxisValues = []
                this.yAxisValues = []
            }
        },
    }, computed: {
        customDateRangeSelected() {
            return this.selectedVitalPeriod === 'Custom Date'
        },
    },
    watch: {
        "graphData": {
            handler: function (value) {
                if (value === null) {
                    this.xAxisValues = []
                    this.yAxisValues = []
                }
                else
                    this.formatRawVitalData(value.data.getVitalReadings)
            },
            deep: true,
            immediate: true
        }
    }
}
</script>