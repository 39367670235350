import { API } from 'aws-amplify';
import { getAllVitalsReadings, getContactDetails, getVitalReadings } from '../../graphql/queries';

export const getVitalReadingsService = async (familyId, contactId, startDate, endDate, vitalTypeId) => {
    let res = await API.graphql({
        query: getVitalReadings,
        variables: { familyId, contactId, startDate, endDate, vitalTypeId }
    });
    return res
}

export const getAllVitalsReadingService = async (familyId, contactId, startDate, endDate) => {
    let res = await API.graphql({
        query: getAllVitalsReadings,
        variables: { familyId, contactId, startDate, endDate, }
    });
    return res
}

export const getContactDetailsService = async (email) => {
    const res = await API.graphql({
        query: getContactDetails,
        variables: { email }
    });
    return {
        contactId: res.data?.getContactDetails?.contactId,
        familyId: res.data?.getContactDetails?.familyId
    }
}