<template>
    <div class="vitals-period-button-set">
        <b-button-group class='vitals-period-buttons' size="md">
            <b-button v-for="(btn, idx) in buttons" :key="idx" :pressed.sync="btn.caption === selectedVitalPeriod"
                @click="vitalPeriodClickHandler(btn.caption)" variant="primary">
                {{ btn.caption }}
                <span class="material-icons-outlined" v-if="btn.caption === 'Custom Date'">
                    event
                </span>
            </b-button>

        </b-button-group>
        <div class="custom-start-end-date-picker" v-if="customDateRangeSelected">
            <div class="start-date">
                <div>
                    <label for="example-input">Choose a Start date</label>
                    <b-input-group class="mb-3">
                        <b-form-datepicker v-model="customStartDate" dropright locale="en-US"
                            :max='new Date().toISOString().split("T")[0]'
                            ></b-form-datepicker>
                    </b-input-group>
                </div>
            </div>
            <div class="end-date">
                <div>
                    <label for="example-input">Choose an End date</label>
                    <b-input-group class="mb-3">
                        <b-form-datepicker v-model="customEndDate" dropright locale="en-US"
                        :max='new Date().toISOString().split("T")[0]'
                            ></b-form-datepicker>
                    </b-input-group>
                </div>
            </div>
            <span class="select-date-range-error errorText bottom-10" v-if="errorMessage.show">{{ errorMessage.message
            }}</span>
            <b-button @click="customRangeSubmitHandler" class="date-range-submit-button">Submit</b-button>
            <b-button @click="resetDateRangeHandler" class="date-range-submit-button">Reset</b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DateRangeSelector',
    data() {
        return {
            buttons: [
                { caption: 'Weekly', state: true },
                { caption: 'Monthly', state: false },
                { caption: 'Custom Date', state: false },

            ],
            selectedVitalPeriod: 'Weekly',
            // vitalStartDate: null,
            // vitalEndDate: null,
            customStartDate: '',
            customEndDate: '',
            formatted: '',
            selected: '',
            errorMessage: {
                show: false,
                message: ''
            },
            customDateRangeSelected: false
        }
    },
    props:{
        defaultVitalPeriod:{
            type:String,
            required:true
        }
    },
    methods: {
        // onContext(ctx) {
        //     console.log("formatted", ctx.selectedFormatted, "selected", ctx.selectedYMD)
        // },
        vitalPeriodClickHandler(period) {
            this.selectedVitalPeriod = period
            if (period === 'Custom Date')
                this.customDateRangeSelected = true
            else {
                this.customDateRangeSelected = false
                this.$emit('vitalPeriodChangeHandler', {
                    period,
                })
            }
        },
        customRangeSubmitHandler() {
            if (this.customStartDate > this.customEndDate) {
                this.errorMessage.show = true
                this.errorMessage.message = 'Start date cannot be greater than End date'
                return
            }
            else if (this.customStartDate === '' && this.customEndDate === '') {
                this.errorMessage.show = true
                this.errorMessage.message = 'Please choose start and end date'
                return
            }
            this.errorMessage.show = false
            this.errorMessage.message = ''
            this.customDateRangeSelected = false
            this.$emit('vitalPeriodChangeHandler', {
                period: this.selectedVitalPeriod,
                customStartDate: this.customStartDate,
                customEndDate: this.customEndDate
            })
        },
        resetDateRangeHandler() {
            this.customStartDate = ''
            this.customEndDate = ''
            this.errorMessage = { show: false, message: false }
        }
    },
    created(){
        this.selectedVitalPeriod=this.defaultVitalPeriod
    }
}
</script>