export const getAlbum = /* GraphQL */ `
  query GetAlbum($albumId: ID!) {
    getAlbum(albumId: $albumId) {
      albumId
      contactId
      createdAt
      familyId
      memories {
        nextToken
      }
      title
      updatedAt
    }
  }
`;
export const getAlbums = /* GraphQL */ `
  query GetAlbums(
    $contactId: ID
    $familyId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getAlbums(
      contactId: $contactId
      familyId: $familyId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        albumId
        contactId
        createdAt
        familyId
        title
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment(
    $appointmentId: ID!
    $familyId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getAppointment(
      appointmentId: $appointmentId
      familyId: $familyId
      limit: $limit
      nextToken: $nextToken
    ) {
      appointmentDate
      appointmentId
      appointmentName
      appointmentStartDate
      appointmentType
      contactId
      createdAt
      createdBy
      endTime
      familyId
      guests
      guestsMetaData {
        contactId
        givenName
      }
      isAllDay
      location
      note
      reccuranceType
      startTime
      taskDescription
      updatedAt
      updatedBy
    }
  }
`;
export const getBlogCategories = /* GraphQL */ `
  query GetBlogCategories {
    getBlogCategories {
      blogCategoryId
      blogCategoryName
    }
  }
`;
export const getBlogsByCategory = /* GraphQL */ `
  query GetBlogsByCategory($blogCategoryId: String!) {
    getBlogsByCategory(blogCategoryId: $blogCategoryId) {
      author
      blogCategoryId
      createdAt
      description
      id
      image
      title
      updatedAt
      url
    }
  }
`;
export const getCalanderDetails = /* GraphQL */ `
  query GetCalanderDetails(
    $contactId: String
    $endDate: String!
    $familyId: ID!
    $startDate: String!
  ) {
    getCalanderDetails(
      contactId: $contactId
      endDate: $endDate
      familyId: $familyId
      startDate: $startDate
    ) {
      appointmentDate
      appointmentId
      appointmentName
      appointmentStartDate
      appointmentType
      contactId
      createdAt
      createdBy
      endTime
      familyId
      guests
      guestsMetaData {
        contactId
        givenName
      }
      isAllDay
      location
      note
      reccuranceType
      startTime
      taskDescription
      updatedAt
      updatedBy
    }
  }
`;
export const getChampConnectToken = /* GraphQL */ `
  query GetChampConnectToken($contactId: ID!, $familyId: ID!) {
    getChampConnectToken(contactId: $contactId, familyId: $familyId) {
      token
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($contactId: ID!, $familyId: ID!) {
    getContact(contactId: $contactId, familyId: $familyId) {
      activeStatus
      config {
        champConnectId
        medicationDisclaimer
      }
      contactId
      createdAt
      email
      familyId
      familyName
      givenName
      invite {
        createdAt
        createdBy
        expireAt
        familyName
        givenName
        status
        username
      }
      isEmergencyContact
      isFamilyOrFriend
      name
      note
      phone
      picture {
        bucket
        key
        region
        uploadUrl
        url
      }
      relationship
      role
      status
      updatedAt
      viewMedication
      viewReminders
      viewVitals
      presenceStatus
    }
  }
`;
export const getContacts = /* GraphQL */ `
  query GetContacts(
    $contactId: ID
    $familyId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getContacts(
      contactId: $contactId
      familyId: $familyId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        activeStatus
        contactId
        createdAt
        email
        familyId
        familyName
        givenName
        isEmergencyContact
        isFamilyOrFriend
        name
        note
        picture {
          bucket
          key
          region
          uploadUrl
          url
        }
        phone
        relationship
        role
        status
        updatedAt
        viewMedication
        viewReminders
        viewVitals
      }
      nextToken
    }
  }
`;

export const getConversation = /* GraphQL */ `
  query GetConversation($contactId: ID!, $familyId: ID!) {
    getConversation(contactId: $contactId, familyId: $familyId) {
      conversationId
      createdAt
      familyId
      participants
    }
  }
`;
export const getCurrentWeather = /* GraphQL */ `
  query GetCurrentWeather($query: String!) {
    getCurrentWeather(query: $query)
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($deviceId: ID!) {
    getDevice(deviceId: $deviceId) {
      contactId
      createdAt
      deviceId
      deviceName
      familyId
      hardwareId
      lastActivityAt
      updatedAt
      vitalTypeIds
    }
  }
`;
export const getDevices = /* GraphQL */ `
  query GetDevices($contactId: ID, $familyId: ID!) {
    getDevices(contactId: $contactId, familyId: $familyId) {
      contactId
      createdAt
      deviceId
      deviceName
      familyId
      hardwareId
      lastActivityAt
      updatedAt
      vitalTypeIds
    }
  }
`;
export const getEquipment = /* GraphQL */ `
  query GetEquipment($contactId: ID, $familyId: ID!) {
    getEquipment(contactId: $contactId, familyId: $familyId) {
      addedDate
      connectedDevices
      contactId
      createdAt
      createdBy
      deviceName
      deviceType
      equipmentId
      familyId
      familyName
      givenName
      pictureUrl
      status {
        battery
        bluetooth
        wifi
      }
    }
  }
`;
export const getFamilies = /* GraphQL */ `
  query GetFamilies {
    getFamilies {
      contactId
      createdAt
      familyId
      familyName
      ownerId
      role
      seniorIds
      updatedAt
    }
  }
`;
export const getInviteStatus = /* GraphQL */ `
  query GetInviteStatus($contactId: ID!, $familyId: ID!) {
    getInviteStatus(contactId: $contactId, familyId: $familyId) {
      contactId
      createdAt
      createdBy
      expireAt
      familyId
      familyName
      givenName
      status
      username
    }
  }
`;
export const getLinks = /* GraphQL */ `
  query GetLinks($familyId: ID!, $nextToken: String) {
    getLinks(familyId: $familyId, nextToken: $nextToken) {
      items {
        createdAt
        createdBy
        description
        familyId
        link
        linkId
      }
      nextToken
    }
  }
`;
export const getMedicationDetails = /* GraphQL */ `
  query GetMedicationDetails(
    $contactId: String
    $date: String!
    $familyId: ID!
    $medicationId: String!
  ) {
    getMedicationDetails(
      contactId: $contactId
      date: $date
      familyId: $familyId
      medicationId: $medicationId
    ) {
      weightUnit
      weight
      supervisor
      startDate
      remainingDays
      refills
      prescriberName
      prescriberInfoAddress
      nextDosageTime
      medicationTypeId
      medicationTypeImageUrl
      medicationName
      medicationId
      issueTo
      isRemind
      heightUnit
      height
      familyId
      endDate
      effectiveDate
      prescriberPhone
      DEA
      ICD10
      SIG
      additionalDetails
      daw
      dosageList {
        detail
        hasPrompted
        hasTaken
        id
        quantity
        time
      }
      durationDays
      daysSupply
      medicationReminderTime
      recurrence
    }
  }
`;
export const getMedicationIntakeHistory = /* GraphQL */ `
  query GetMedicationIntakeHistory(
    $contactId: String
    $familyId: ID!
    $medicationId: String!
    $month: String!
    $year: String!
  ) {
    getMedicationIntakeHistory(
      contactId: $contactId
      familyId: $familyId
      medicationId: $medicationId
      month: $month
      year: $year
    ) {
      contactId
      familyId
      history {
        date
        medicationStatus
      }
      medicationId
      missedDayCount
      remainingDayCount
    }
  }
`;
export const getMedicationList = /* GraphQL */ `
  query GetMedicationList($contactId: String, $date: String!, $familyId: ID!) {
    getMedicationList(contactId: $contactId, date: $date, familyId: $familyId) {
      contactId
      familyId
      medicationList {
        date
        imageUrl
        medicationId
        medicationName
        nextDosageTime
        nextDosage {
          detail
          quantity
          time
        }
        createdByUserType
      }
    }
  }
`;
export const getMedicationType = /* GraphQL */ `
  query GetMedicationType($medicationTypeId: ID!) {
    getMedicationType(medicationTypeId: $medicationTypeId) {
      hasQuantity
      imageUrl
      medicationType
      medicationTypeId
      quantityLabel
    }
  }
`;
export const getMedicationTypes = /* GraphQL */ `
  query GetMedicationTypes {
    getMedicationTypes {
      hasQuantity
      imageUrl
      medicationType
      medicationTypeId
      quantityLabel
    }
  }
`;
export const getMemories = /* GraphQL */ `
  query GetMemories(
    $contactId: ID
    $familyId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getMemories(
      contactId: $contactId
      familyId: $familyId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bucket
        contactId
        createdAt
        familyId
        isOwner
        key
        mediaId
        url
      }
      nextToken
    }
  }
`;

export const getSharedPhotosForCounselor = /* GraphQL */ `
  query GetMemories(
    $familyId: ID!
    $contactId: ID
    $limit: Int
    $nextToken: String
  ) {
    getMemories(
      familyId: $familyId
      contactId: $contactId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        mediaId
        url
        key
        bucket
        contactId
      }
      nextToken
    }
  }
`;

export const getMemory = /* GraphQL */ `
  query GetMemory($contactId: ID, $mediaId: ID!) {
    getMemory(contactId: $contactId, mediaId: $mediaId) {
      bucket
      contactId
      createdAt
      familyId
      isOwner
      key
      mediaId
      url
    }
  }
`;
export const getMessages = /* GraphQL */ `
  query GetMessages(
    $conversationId: ID!
    $limit: Int
    $lastMessageId: String
    $sortOrder: SortOrder
  ) {
    getMessages(
      conversationId: $conversationId
      limit: $limit
      lastMessageId: $lastMessageId
      sortOrder: $sortOrder
    ) {
      conversationId
      createdAt
      data
      messageId
      receivedAt
      receivedBy
      readAt
      sentAt
      sentBy
      tempId
      text
      type
      senderName
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile {
    getProfile {
      email
      families {
        familyId
        role
      }
      familyName
      givenName
      name
      phone
      picture
    }
  }
`;
export const getSafetyDisclaimer = /* GraphQL */ `
  query GetSafetyDisclaimer($contactId: ID, $familyId: ID!) {
    getSafetyDisclaimer(contactId: $contactId, familyId: $familyId) {
      config {
        champConnectId
        medicationDisclaimer
      }
      contactId
      content
    }
  }
`;
export const getSeniors = /* GraphQL */ `
  query GetSeniors {
    getSeniors {
      activeStatus
      config {
        champConnectId
        medicationDisclaimer
      }
      contactId
      createdAt
      email
      familyId
      familyName
      givenName
      invite {
        createdAt
        createdBy
        expireAt
        familyName
        givenName
        status
        username
      }
      isEmergencyContact
      isFamilyOrFriend
      name
      note
      phone
      picture {
        bucket
        key
        region
        uploadUrl
        url
      }
      relationship
      role
      status
      updatedAt
      viewMedication
      viewReminders
      viewVitals
    }
  }
`;

export const getContactDetails = /* GraphQL */ `
  query GetContactDetails($email: String!) {
    getContactDetails(email: $email) {
      contactId
      familyId
    }
  }
`;

export const getTopStories = /* GraphQL */ `
  query GetTopStories {
    getTopStories {
      author
      blogCategoryId
      createdAt
      description
      id
      image
      title
      updatedAt
      url
    }
  }
`;
export const getVitalReadings = /* GraphQL */ `
  query GetVitalReadings(
    $contactId: ID
    $endDate: String
    $familyId: ID!
    $startDate: String
    $vitalTypeId: ID!
  ) {
    getVitalReadings(
      contactId: $contactId
      endDate: $endDate
      familyId: $familyId
      startDate: $startDate
      vitalTypeId: $vitalTypeId
    ) {
      avg
      max {
        readAt
        value
      }
      min {
        readAt
        value
      }
      readings {
        contactId
        createdAt
        deviceId
        familyId
        hardwareId
        isManual
        readAt
        reading
        readingId
        vitalTypeId
      }
    }
  }
`;

export const getAllVitalsReadings = /* GraphQL */  `
query GetAllVitalsReadings(
  $contactId: ID
  $endDate: String
  $familyId: ID!
  $startDate: String
) {
  getAllVitalsReadings(contactId:$contactId,
familyId:$familyId,
startDate: $startDate, 
endDate: $endDate){
  readings{
    vitalTypeId
    reading
    readAt
    }
  }
}`

export const getVitalTypes = /* GraphQL */ `
  query GetVitalTypes {
    getVitalTypes {
      vitalTypeId
      vitalTypeName
    }
  }
`;
export const getVitals = /* GraphQL */ `
  query GetVitals($contactId: ID, $familyId: ID!) {
    getVitals(contactId: $contactId, familyId: $familyId) {
      vitalTypeName
      activityStatus
    }
  }
`;
export const hello = /* GraphQL */ `
  query Hello {
    hello
  }
`;
export const searchBlogs = /* GraphQL */ `
  query SearchBlogs($blogCategoryId: String!, $blogTitle: String!) {
    searchBlogs(blogCategoryId: $blogCategoryId, blogTitle: $blogTitle) {
      author
      blogCategoryId
      createdAt
      description
      id
      image
      title
      updatedAt
      url
    }
  }
`;
export const searchContacts = /* GraphQL */ `
  query SearchContacts(
    $familyId: ID!
    $limit: Int
    $name: String!
    $nextToken: String
  ) {
    searchContacts(
      familyId: $familyId
      limit: $limit
      name: $name
      nextToken: $nextToken
    ) {
      items {
        activeStatus
        contactId
        createdAt
        email
        familyId
        familyName
        givenName
        isEmergencyContact
        isFamilyOrFriend
        name
        note
        picture {
          bucket
          key
          region
          uploadUrl
          url
        }
        phone
        relationship
        role
        status
        updatedAt
        viewMedication
        viewReminders
        viewVitals
      }
      nextToken
    }
  }
`;
export const getUserDevice = /* GraphQL */ `
  query GetUserDevice($userDeviceId: ID, $contactId: ID) {
    getUserDevice(userDeviceId: $userDeviceId, contactId: $contactId) {
      userDeviceId
      contactId
      deviceName
      deviceStatus {
        userDeviceId
        contactId
        battery
        wifi
        bluetooth
      }
      deviceType
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const getAppInformation = /* GraphQL */ `
  query GetAppInformation {
    getAppInformation {
      help {
        documentName
        documentUrl
      }
      privacy {
        documentName
        documentUrl
      }
      terms {
        documentName
        documentUrl
      }
      version
    }
  }
`;
export const getUserPresence = /* GraphQL */ `
  query GetUserPresence($familyId: ID, $contactId: ID) {
    getUserPresence(familyId: $familyId, contactId: $contactId) {
      contactId
      familyId
      presenceStatus
    }
  }
`;
export const getPhotos = /* GraphQL */ `
  query GetMemories($familyId: ID!, $limit: Int) {
    getMemories(familyId: $familyId, limit: $limit) {
      items {
        createdAt
        bucket
        contactId
        url
        mediaId
        key
        isOwner
        familyId
        isNewMemory
      }
    }
  }
`;
export const getUserSummery = /* GraphQL */ `
  query GetUserSummery($familyId: ID!) {
    getUserSummery(familyId: $familyId) {
      newMemoryCount
    }
  }
`;
export const getAllKioskNotifications = /* GraphQL */`
  query getAllKioskNotifications($companyId: Int!) {
    getAllKioskNotifications(companyId:$companyId) {
      messageId
      startTime
      endTime
      message
      users
      creationTime
      companyId
    }
  }
`;
