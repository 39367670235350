<template>
    <div class="vitals-graph-body">
        <div class="vitals-graph-container">
            <v-chart v-if='xAxisValues.length > 0 && seriesValues !== null' class="chart" :option="getOption()"
                autoresize />
            <div v-else>
                <h3 class="graphical-view-no-reading">Vital Readings not available</h3>
            </div>
            <div id="myDiv"></div>
        </div>
        <div class="vitals-stat-container pt-0" v-if='xAxisValues.length > 0 && seriesValues !== null'>
            <div class="stat-block justify-content-center">
                <div class="stat-block">
                    <!-- <div class="stat-block-item">
                        <span class="stat-block-heading">Min</span>
                        <div class="stat-block-value">
                            <span class="value">{{ minVal.slice(0, 5) }}</span>
                           
                            <span class="unit">{{ selectedVital.unit }}</span>
                        </div>
                    </div> -->
                    <div class="stat-block-item">
                        <span class="stat-block-heading">Systolic Average</span>
                        <div class="stat-block-value">
                            <span class="value">{{ systolicAvg.slice(0, 5) }}</span>

                            <span class="unit">{{ selectedVital.unit }}</span>
                        </div>
                    </div>
                    <div class="stat-block-item">
                        <span class="stat-block-heading">Diastolic Average</span>
                        <div class="stat-block-value">
                            <span class="value">{{ diastolicAvg.slice(0, 5) }}</span>

                            <span class="unit">{{ selectedVital.unit }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
} from 'echarts/components';
import dayjs from '@/util/dayjs';
import VChart, { THEME_KEY } from 'vue-echarts';
import * as echarts from 'echarts';
use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
]);
export default {
    name: 'LineWithCurvyChart',
    components: {
        VChart,
    },
    data() {
        return {

            buttons: [
                { caption: 'Weekly', state: true },
                { caption: 'Monthly', state: false },
                { caption: 'Custom Date', state: false },

            ],
            selectedVitalPeriod: 'Weekly',
            vitalStartDate: null,
            vitalEndDate: null,
            customRangeStartvalue: '',
            customRangeEndvalue: '',
            formatted: '',
            selected: '',
            seriesValues: [],
            xAxisValues: [],
            minVal: null,
            maxVal: null,
            avgval: null,
            systolicAvg:"0",
            diastolicAvg:"0"
            // unit: "mg/dL"
        }
    },
    props: {
        vital: {
            type: Object,
            required: false
        },
        graphData: {
            type: Object
        },
        selectedVital: {
            type: Object,
            required: false
        },
    },
    methods: {

        vitalPeriodClickHandler(period) {
            this.selectedVitalPeriod = period
        },
        getOption() {
            return {
                tooltip: {
                    trigger: 'axis',
                    valueFormatter: (value) => `${value} ${this.selectedVital.unit}`
                },
                grid: {
                    left: '4%',
                    // right: '4%',
                    top: '3%',
                    bottom: '2%',
                    containLabel: true,
                    // show:true,
                    width: "auto",
                    background: "red",
                    // backgroundColor:"red",
                    backgroundColor:
                        "#ccc",
                },
                // toolbox: {
                //     feature: {
                //         saveAsImage: {}
                //     }
                // },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    data: [...this.xAxisValues,
                        // ...this.xAxisValues,
                        // ...this.xAxisValues,
                    ],
                    axisLabel: {
                        show: true,
                        // interval:2,
                        // interval: (index, value, x) => {
                        //   if (index%5==0)
                        //     return false
                        //   return true
                        // },
                        rotate: 60,
                        // fontSize:"8px",
                        position: 'bottom'
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                },
                series: [
                    {
                        name: 'Systolic',
                        type: 'line',
                        smooth: true,
                        stack: 'Total',
                        showSymbol: false,
                        data: [...this.seriesValues[0],
                        ],
                        // markPoint: {
                        //     symbol: 'roundRect',
                        //     data: [
                        //         {
                        //             name: 'maximum',
                        //             type: 'max'
                        //         },

                        //     ],
                        //     label: {
                        //         color: "black"
                        //     },
                        //     itemStyle: {
                        //         color: "rgba(255,255,255,0.5)"
                        //     }
                        // },
                        lineStyle: {
                            color: "#5359c3",
                            width: 4,
                            opacity: 0.8
                        },
                        areaStyle: {
                            opacity: 0.1,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(58,77,233,0.8)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(58,77,233,0.4)'
                                }
                            ])
                        },
                    },
                    {
                        name: 'Diastolic',
                        type: 'line',
                        smooth: true,
                        stack: 'Total',
                        showSymbol: false,
                        data: [...this.seriesValues[1], ...this.seriesValues[1]],
                        lineStyle: {
                            color: "#5359c3",
                            width: 2,
                            opacity: 0.8,
                            type: 'dotted'
                        },
                        areaStyle: {
                            opacity: 0.1,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(58,77,233,0.8)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(58,77,233,0.4)'
                                }
                            ])
                        },
                    }
                ]
            }
        },
        formatRawVitalData(rawData) {
            let { avg, max, min, readings } = rawData;
            let readingsArr = [];
            if (readings.length > 0) {
                readingsArr.push(readings.map((item) => ({ readAt: dayjs(item.readAt).format('MM/DD/YY h:mm A'), reading: Number(JSON.parse(item.reading).sys) })).reverse())
                readingsArr.push(readings.map((item) => ({ readAt: dayjs(item.readAt).format('MM/DD/YY h:mm A'), reading: Number(JSON.parse(item.reading).dia) })).reverse())

                this.maxVal = max.value
                this.avgVal = avg
                let seriesArr = []
                readingsArr.forEach((readingArr, idx) => {
                    seriesArr.push([...readingArr.map(item => item.reading)])
                    if (idx == 0 && readingArr.length > 0)
                        this.systolicAvg = (readingArr.reduce((acc, item,) => { return acc + item.reading }, 0) / readingArr.length).toString()
                    else
                        this.diastolicAvg=(readingArr.reduce((acc, item,) => { return acc + item.reading }, 0) / readingArr.length).toString()
                })
                this.seriesValues = seriesArr
                this.xAxisValues = [
                    ...readingsArr[0].map(item => item.readAt),
                ]
            } else {
                this.xAxisValues = [];
                this.seriesValues = null
            }
        }
    }, computed: {
        customDateRangeSelected() {
            return this.selectedVitalPeriod === 'Custom Date'
        },

    },
    watch: {
        "graphData": {
            handler: function (value) {
                this.formatRawVitalData(value.data.getVitalReadings);
            },
            deep: true,
            immediate: true
        }
    }
}
</script>