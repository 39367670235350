<template>
    <div>
        <div class="vitals-page">
            <div class="vitals-page-header">
                <MemberChangeDropdown :page="page" @action="goToPage" :currentClientId="currentClientId" @back="back()" />
                <div class="vitals-view-switch-buttons">
                    <b-button-group class='vitals-view-buttons' size="lg">
                        <b-button v-for="(btn, idx) in viewbuttons" :key="idx" class="d-flex align-items-center gap-1"
                            :pressed.sync="currentVitalView === btn.caption" @click="changeVitalView(btn.caption)"
                            variant="primary">
                            <span class="material-symbols-outlined" v-if="btn.caption === 'Graphical View'">
                                monitoring
                            </span>
                            <span class="material-symbols-outlined" v-if="btn.caption === 'Tabular View'">
                                table
                            </span>
                            <span>
                                {{ btn.caption }}
                            </span>
                        </b-button>

                    </b-button-group>
                </div>
            </div>
            <div class="vitals-hero-component">
                <div v-if="this.currentVitalView == 'Graphical View'" class="vitals-graphical-view">
                    <div class="vitals-slider">
                        <div class="vitals-slider-item" v-for="(vitalType, idx) in allVitals" :key="idx">
                            <button :class="selectedVital.label == vitalType.label ? 'selected-vital' : ''"
                                @click="changeSelectedVital(vitalType)">
                                <div class="vital-slider-item-icon">
                                    <span class="material-symbols-outlined" v-if="vitalType.label === allVitals[0].label">
                                        ecg_heart
                                    </span>
                                    <span class="material-symbols-outlined" v-if="vitalType.label === allVitals[1].label">
                                        glucose
                                    </span>
                                    <span class="material-symbols-outlined" v-if="vitalType.label === allVitals[2].label">
                                        spo2
                                    </span>
                                    <span class="material-symbols-outlined" v-if="vitalType.label === allVitals[3].label">
                                        blood_pressure
                                    </span>

                                    <span class="material-symbols-outlined" v-if="vitalType.label === allVitals[4].label">
                                        device_thermostat
                                    </span>
                                </div>
                                <div class="vital-slider-item-label">
                                    <span class="">
                                        {{ vitalType.label }}
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="vitals-header-graph-container">
                        <div class="vitals-graph-header">
                            <span class="selected-vital-label">
                                {{ selectedVital.label }}
                            </span>
                            <DateRangeSelector @vitalPeriodChangeHandler="vitalPeriodChangeHandler"  :defaultVitalPeriod="selectedVitalPeriod"/>
                        </div>
                        <LineWithAreaChart
                            v-if="(selectedVital.vitalId === 'heartRate' || selectedVital.vitalId === 'spo2' || selectedVital.vitalId === 'temp')"
                            :graphData="vitalDataRaw" :unit="'BPM'" :selectedVital="selectedVital" />
                        <LineWithCurvyChart v-if="this.selectedVital.vitalId === 'gl' && vitalDataRaw !== null"
                            :graphData="vitalDataRaw" />
                        <MultiLineChart v-if="this.selectedVital.vitalId === 'bp' && vitalDataRaw !== null"
                            :graphData="vitalDataRaw" :selectedVital="selectedVital" />

                    </div>
                </div>
                <div v-else class="vitals-tabular-view">
                    <!-- Tabular View -->
                    <div class="vitals-tabular-view-header">
                        <b-dropdown id="dropdown-1" class='filter-by-dropdown' dropdown>
                            <template #button-content>
                                <div class="tabular-view-filter-by">
                                    <div>
                                        <span>Filter By</span>
                                    </div>
                                    <div>
                                        <span class="material-icons-outlined tune-icon">
                                            tune
                                        </span>
                                    </div>

                                </div>
                            </template>
                            <div class="filter-template-content">
                                <div v-for="(item, idx) in filterByOptions" :key="idx" class="vital-filter-item">
                                    <div><label>{{ item.name }}</label></div>
                                    <Checkbox class="" @input="vitalFilterChangeError" :name="item.name"
                                        :id="idx.toString()" label="" v-model="item.selected" />
                                </div>
                            </div>
                        </b-dropdown>
                        <!-- <b-button class="manage-threshold-button" disabled>Manage Thresholds</b-button> -->
                        <DateRangeSelector @vitalPeriodChangeHandler="vitalPeriodChangeHandler" :defaultVitalPeriod="selectedVitalPeriod" />

                    </div>
                    <div class="vitals-tabular-view-table">
                        <b-table id="my-table" :hover="true" :items="tableItems" :fields="fields"
                            :current-page="currentPage" :per-page="perPage">
                        </b-table>
                        <h3 v-if="tableItems.length==0" class="tabular-view-no-reading">Vital Readings not available</h3>
                        <div class="b-table-pagination">
                            <div class="pagination-count-per-page">
                                <div class="pagination-number-per-page">
                                    <div>
                                        <span>Per page:</span>
                                    </div>
                                    <div>
                                        <select style="" @change="changeCountPerPage">
                                            <option v-for="item in perPageOptions" :key="item" :value="item">
                                                {{ item }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <b-pagination align="right" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                                aria-controls="my-table" first-text="First" prev-text="Prev" next-text="Next"
                                last-text="Last"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loading v-if="loading == true" />
    </div>
</template>

<script>
import MemberChangeDropdown from '../../components/general/dropdownButtons/MemberChangeDropdown.vue'
import DateRangeSelector from '../../components/vitals/DateRangeSelector.vue'
import LineWithAreaChart from '../../components/vitals/graphs/LineWithAreaChart.vue'
import Loading from '@/components/general/loading/loading.vue';
import LineWithCurvyChart from '../../components/vitals/graphs/LineWithCurvyChart.vue'
import MultiLineChart from '../../components/vitals/graphs/MultiLineChart.vue'
import dayjs from '@/util/dayjs';
import { VitalUnits, Vitals } from '@/util/globalConstants'
import { getVitalReadingsService, getContactDetailsService, getAllVitalsReadingService } from '@/util/helpers/graphql/vitals.graphql.js';
export default {
    name: 'Vitals',
    components: { MemberChangeDropdown, DateRangeSelector, LineWithAreaChart, Loading, LineWithCurvyChart, MultiLineChart },
    data() {
        return {
            perPageOptions: [5, 10, 20, 50, 100],
            perPage: 5,
            currentPage: 1,
            slide: 0,
            sliding: false,
            currentVitalView: 'Graphical View',
            selectedVital: {
                vitalId: 'heartRate',
                label: 'Heart Rate',
                unit: "bpm"
            },
            allVitals: [
                {
                    vitalId: 'heartRate',
                    label: Vitals.HEART_RATE,
                    unit: "bpm"
                },
                {
                    vitalId: 'gl',
                    label: Vitals.BLOOD_SUGAR
                },
                {
                    vitalId: 'spo2',
                    label: Vitals.PULSE_OX,
                    unit: "SpO2"
                },
                {
                    vitalId: 'bp',
                    label: Vitals.BLOOD_PRESSURE,
                    unit: "mmHg"
                },

                {
                    vitalId: 'temp',
                    label: Vitals.TEMPERATURE,
                    unit: "°F"
                },
            ],
            buttons: [
                { caption: 'Weekly', state: true },
                { caption: 'Monthly', state: false },
                { caption: 'Custom Date', state: false },

            ],
            viewbuttons: [
                { caption: 'Graphical View', state: true },
                { caption: 'Tabular View', state: false },
            ],
            page: {
                label: 'Vitals',
                name: 'Vitals',
            },
            filterByOptions: [
                {
                    name: Vitals.HEART_RATE,
                    selected: false,
                },
                {
                    name: Vitals.BLOOD_PRESSURE,
                    selected: false,
                },
                {
                    name: Vitals.BLOOD_SUGAR,
                    selected: false,
                },
                {
                    name: Vitals.TEMPERATURE,
                    selected: false,
                },
                {
                    name: Vitals.PULSE_OX,
                    selected: false,
                },
            ],
            currentClientId: this.$route.params.client_id,
            selectedVitalPeriod: 'Weekly',
            vitalStartDate: null,
            vitalEndDate: null,
            customRangeStartvalue: '',
            customRangeEndvalue: '',
            formatted: '',
            selected: '',
            clientInfo: null,
            loading: false,
            clientFamilyId: null,
            clientContactId: null,
            fields: [
                {
                    key: 'S NO.',
                    label: 'S NO.',
                    sortable: true
                },
                {
                    key: 'Vital Name',
                    label: 'Vital Name',
                    sortable: true
                },
                {
                    key: 'Vital taken Date and Time',
                    label: 'Vital taken Date and Time',
                    sortable: true
                },
                {
                    key: 'Vital Reading/Measure',
                    label: 'Vital Reading/Measure',
                    sortable: false
                },
            ],
            tableItemsOriginal: [],
            tableItems: [],
            vitalDataRaw: null,
            vitalDataTabular: null,

        }
    },
    methods: {
        changeCountPerPage({ target: { value } }) {
            this.perPage = Number(value)
        },
        back() {
            this.$router.push({
                label: 'ClientOverview',
                name: 'ClientOverview', "params": { "record_id": this.$route.params.client_id }
            });

        },
        vitalFilterChangeError() {

            let selectedVitals = this.filterByOptions.filter(item => item.selected).map(item => item.name)
            if (selectedVitals.length == 0) {
                this.tableItems = this.tableItemsOriginal
            }
            else {
                this.tableItems = this.tableItemsOriginal.filter(item => selectedVitals.includes(item["Vital Name"]))
            }
        },
        setDefaultStartAndEndDate() {
            const endDate = dayjs(new Date()).utc().format('YYYY-MM-DD');
            const startDate = dayjs(endDate).utc().subtract(6, 'day').format('YYYY-MM-DD')
            this.vitalStartDate = startDate
            this.vitalEndDate = endDate
        },
        async goToPage({ page, selectedClient }) {
            this.clientInfo = selectedClient
            await this.fetchContactDetails()
            if (this.currentVitalView === 'Graphical View') {
                await this.fetchVitals()
                this.tableItems = []
                this.tableItemsOriginal = []
            }
            else {
                await this.fetchVitalsForTabularView()
            }
            this.$router.push(page)
        },
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        },
        async changeVitalView(value) {
            this.currentVitalView = value
            if (value === 'Graphical View') {
                this.tableItems = []
                this.tableItemsOriginal = []
                await this.fetchVitals()
            }
            else
                await this.fetchVitalsForTabularView()
        },
        async changeSelectedVital(value) {
            this.selectedVital = value
            this.vitalDataRaw = null
            this.loading = true
            await this.fetchVitals()
            this.loading = false
        },
        async vitalPeriodChangeHandler({ period, customStartDate, customEndDate }) {
            try {
                this.selectedVitalPeriod = period
                let endDate = dayjs(new Date()).format('YYYY-MM-DD'), startDate = null;
                if (period === 'Weekly') {
                    startDate = dayjs(endDate).utc().subtract(6, 'day').format('YYYY-MM-DD')
                    this.vitalStartDate = startDate
                    this.vitalEndDate = endDate
                }
                else if (period === 'Monthly') {
                    startDate = dayjs(endDate).utc().subtract(29, 'day').format('YYYY-MM-DD')
                    this.vitalStartDate = startDate
                    this.vitalEndDate = endDate
                }
                else if (period == 'Custom Date') {
                    this.vitalStartDate = dayjs(customStartDate).utc()
                    this.vitalEndDate = dayjs(customEndDate).utc()

                    // return
                }
                if (this.currentVitalView === 'Graphical View') {

                    await this.fetchVitals()
                }

                else {
                    await this.fetchVitalsForTabularView()
                }
            }
            catch (err) {
                console.log(err)
            }
        },
        async fetchVitals() {
            this.loading = true
            let vitals = await getVitalReadingsService(this.clientFamilyId,
                this.clientContactId,
                this.vitalStartDate,
                this.vitalEndDate,
                this.selectedVital.
                    vitalId)
            if (vitals?.data?.getVitalReadings)
                this.vitalDataRaw = vitals
            this.loading = false
        },
        async fetchVitalsForTabularView() {
            this.loading = true
            let vitals = await getAllVitalsReadingService(this.clientFamilyId,
                this.clientContactId,
                this.vitalStartDate,
                this.vitalEndDate,
            )
            if (vitals?.data?.getAllVitalsReadings)
                this.vitalDataTabular = this.formatVitalsDataForTable(vitals?.data?.getAllVitalsReadings.readings)
            this.loading = false
        },
        formatVitalsDataForTable(data) {
            let formattedItems = []
            let tableIndex=0
            data.forEach((item, idx) => {
                let tableItem = {}
               
                if (item.vitalTypeId!==null &&item.reading!==null && !['sleep', 'ws', 'activity'].includes(item.vitalTypeId)) {
                    const { name, unit, readingKey } = VitalUnits[item.vitalTypeId]
                    tableItem["S NO."] = (tableIndex++) + 1
                    tableItem["Vital Name"] = name
                    tableItem["Vital taken Date and Time"] = dayjs(item.readAt).format('MM/DD/YY h:mm A')
                    let parsedReading = JSON.parse(item.reading), reading = "";
                    readingKey.forEach((key, idx) => {
                        if (item.vitalTypeId == 'bp')
                            reading += `${key} - ${parsedReading[key]} ${unit[idx]}, `
                        else if (item.vitalTypeId == 'spo2')
                            reading += `${parsedReading[key]}% ${unit[idx]}, `
                        else
                            reading += `${parsedReading[key]} ${unit[idx]}, `
                    })
                    tableItem["Vital Reading/Measure"] = reading.trim().slice(0, reading.length - 2)
                    formattedItems.push(tableItem)
                }

            })
            this.tableItemsOriginal = formattedItems
            this.tableItems = formattedItems
        },
        async fetchContactDetails() {
            this.loading = true
            let res = await getContactDetailsService(this.clientInfo.email)
            this.clientContactId = res?.contactId
            this.clientFamilyId = res?.familyId
            this.loading = false
        }
    },
    computed: {
        rows() {
            return this.tableItems.length
        }
    },

    async created() {
        this.setDefaultStartAndEndDate()
    },
}
</script>